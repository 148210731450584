import React from 'react'
import { pdfjs } from 'react-pdf'

import { Document, Page, Text, View, Link, Image } from '@react-pdf/renderer'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import LogoLekto from './Fonts/logo.png'
import {
  EvidenceFixed,
  IPDFDocumentProps,
  ProjectDetailsProps,
  projectStageOrientation,
} from './PDFDocument.interfaces'
import * as S from './PDFDocument.styles'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

export const PDFDocument = ({ steps, txTitleLesson, title, txOrientation }: IPDFDocumentProps) => {
  const fixedEvidence = [steps[0]?.projectStage.evidenceFixed]

  const getColorForIndex = (index: number) => {
    const colors = ['#8c22bc', '#e14983', '#e66251']
    const colorIndex = index % colors.length
    return colors[colorIndex]
  }

  const Logo = () => <Image style={S.styles.logo} src={LogoLekto} />

  const removeTagsAndFormatBR = (htmlString: string): string => {
    const div = document.createElement('div')
    div.innerHTML = htmlString
    const text = div.textContent || div.innerText || ''

    const formattedText = text.replace(/<br\s*\/?>/gi, '\n')
    return formattedText
  }
  return (
    <Document>
      <Page style={S.styles.page}>
        {steps && txTitleLesson && title && LogoLekto && (
          <>
            <Logo />
            {/* Título */}
            <Text style={S.styles.title}>Planejamento de Aula</Text>
            {/* Subtítulo */}
            <Text style={S.styles.subtitle}>{title}</Text>
            <View style={S.styles.divider} />
            <View style={[S.styles.row]}>
              <Text style={[S.styles.sectionTitle, { color: 'black' }]}>Habilidade Fixa</Text>
            </View>
            {fixedEvidence &&
              fixedEvidence.length > 0 &&
              fixedEvidence.map((fixed: EvidenceFixed) => (
                <>
                  <View style={S.styles.box}>
                    <View style={S.styles.listItemBox}>
                      <Text style={S.styles.titleBox}>{fixed.ability?.txName}</Text>
                    </View>

                    <Text style={S.styles.paragraphBox}> {fixed?.txName}</Text>
                    <View style={S.styles.divider} />
                    <Text style={S.styles.titleBoxCompetence}>Competência</Text>
                    <View style={S.styles.listItemBox}>
                      <Text style={S.styles.titleBox}>{fixed?.ability?.competence?.txName}</Text>
                    </View>
                  </View>
                </>
              ))}
            <View style={[S.styles.row]}>
              <Text style={[S.styles.sectionTitleThin, { color: 'black' }]}>Habilidades Variáveis</Text>
            </View>
            {steps &&
              steps.length > 0 &&
              steps.map((evidences: ProjectDetailsProps, index: number) => (
                <React.Fragment key={index}>
                  <View style={S.styles.listItemMap}>
                    <Text style={[S.styles.sectionTitleThin, { color: getColorForIndex(index) }]}>
                      {evidences?.projectStage?.project?.projectCategory?.[0]?.category?.txName} -
                    </Text>
                    <Text style={[S.styles.sectionTitleBold, { color: getColorForIndex(index) }]}>
                      {' '}
                      {evidences?.projectStage?.project?.txTitle}
                    </Text>
                  </View>
                  <View style={S.styles.box}>
                    <View style={S.styles.listItemBox}>
                      <Text style={S.styles.titleBox}>
                        {evidences?.projectStage?.evidenceVariable?.ability?.txName}
                      </Text>
                    </View>

                    <Text style={S.styles.paragraphBox}> {evidences?.projectStage?.evidenceVariable?.txName}</Text>
                    <View style={S.styles.divider} />
                    <Text style={S.styles.titleBoxCompetence}>Competência</Text>
                    <View style={S.styles.listItemBox}>
                      <Text style={S.styles.titleBox}>
                        {evidences?.projectStage?.evidenceVariable?.ability?.competence?.txName}
                      </Text>
                    </View>
                  </View>
                </React.Fragment>
              ))}
            <View style={S.styles.divider} />
            {/* Tópico "Orientações Gerais" */}
            <Text style={S.styles.sectionTitle}>Orientações Gerais</Text>
            <Text style={S.styles.paragraph}>{removeTagsAndFormatBR(txOrientation)}</Text>
            <View style={S.styles.divider} />
            {steps?.map((step: ProjectDetailsProps, stepIndex: number) => {
              return (
                <>
                  <View style={[S.styles.row, { color: getColorForIndex(stepIndex) }]}>
                    <Text style={[S.styles.sectionTitle, { color: getColorForIndex(stepIndex) }]}>
                      Etapa {stepIndex + 1}
                    </Text>
                    <View style={S.styles.divider} />
                  </View>

                  <>
                    {step && (
                      <>
                        <View style={[S.styles.row, { color: getColorForIndex(stepIndex) }]}>
                          <Text style={[S.styles.sectionTitle, { color: getColorForIndex(stepIndex) }]}>
                            {step?.projectStage?.project?.projectCategory?.[0]?.category?.txName} -{' '}
                            {step?.projectStage?.project?.txTitle}
                          </Text>
                        </View>
                        <View style={S.styles.divider} />
                      </>
                    )}
                    {step?.projectStage?.projectStageOrientation?.map(
                      (orientation: projectStageOrientation, index: number) => (
                        <>
                          <Text key={index} style={[S.styles.sectionTitle, { color: getColorForIndex(stepIndex) }]}>
                            {orientation?.txTitle}
                          </Text>
                          <Text key={index} style={S.styles.paragraph}>
                            {removeTagsAndFormatBR(orientation?.txOrientationCode)}
                          </Text>
                        </>
                      ),
                    )}
                    <>
                      <Text style={[S.styles.sectionTitle, { color: getColorForIndex(stepIndex) }]}>
                        Referências de Apoio
                      </Text>
                      <View style={S.styles.listItem}>
                        <Text style={S.styles.bullet}>-</Text>
                        <Link src={step?.projectStage?.mediaInformation?.txAbsoluteUrl} target="_blank">
                          <Text style={S.styles.linkText}>{step?.projectStage?.mediaInformation?.txName}</Text>
                        </Link>
                      </View>
                    </>
                  </>
                </>
              )
            })}
          </>
        )}
      </Page>
    </Document>
  )
}
