import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useAuth } from '~/_context/Auth'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { useGetTrailById } from '~/services/Trails'
import { Moment } from '~/services/Trails/types'

import {
  AppBarComponent,
  CardDescription,
  ContainerComponent,
  DividerComponent,
  ModalBNCCComponent,
  ScheduleModalComponent,
  SkeletonComponent,
} from '~/components'
import CardDetailTrail from '~/components/Cards/CardDetailTrail/CardDetailTrail'
import StageSteps from '~/components/Navigation/StageSteps/StageSteps'

import { TrailsHeaderComponent } from '~/containers/private/EM'
import { CardProjectComponent } from '~/containers/private/Trails/TrailDetailsProject'

import { GET_CLASS_INFOS_BY_CLASSID } from '~/pages/private/Trails/ListTrailsAvailable/schemas.queries'

import useIsMobile from '~/validations/isMobile'
import { validateType } from '~/validations/setTypeModal'

import * as S from './styles'

export const TrailsGeneratedEMView = () => {
  const { trailId, classId } = useParams()
  const {
    data: dataClassDetails,
    remove: removeClassDetails,
    isLoading: isLoadingTrailsDetails,
    refetch: refetchDataClass,
  } = useGetTrailById(Number(classId), Number(trailId))
  const { user } = useAuth()
  const navigate = useNavigate()
  const idTrack = dataClassDetails?.idTrack

  const { data: dataClassInfos, remove: removeClassInfos } = useQueryGraphQLClient(
    'network',
    'GET_CLASS_INFOS_BY_CLASSID',
    GET_CLASS_INFOS_BY_CLASSID,
    {
      classId: Number(classId),
    },
  )
  const sortedLessons = dataClassDetails?.stage?.sort((a, b) => a.nuOrder - b.nuOrder) || []
  const sortedMoments = dataClassDetails?.moment?.sort(
    (a, b) => a.projectTrackStage?.nuOrder - b.projectTrackStage?.nuOrder,
  )
  const isMobile = useIsMobile()

  const [moments, setMoments] = useState<Moment[] | undefined>(undefined)
  const [openModalBNCC, setOpenModalBNCC] = useState<boolean>(false)
  const [openModalTime, setOpenModalTime] = useState<boolean>(false)
  const [checkMyLessons, setCheckMyLessons] = useState<boolean>(false)
  const [hasScrolled, setHasScrolled] = useState(false)
  const [trailIndex, setTrailIndex] = useState<number | null>(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const itemsPerPage = dataClassDetails?.stage?.length <= 4 ? 4 : 3
  const totalCards = dataClassDetails?.stage?.length || 0

  const maxInitialIndex = totalCards - itemsPerPage
  const adjustedCurrentIndex = Math.min(currentIndex, maxInitialIndex)
  const isPrevDisabled = currentIndex === 0
  const isNextDisabled = currentIndex >= totalCards - itemsPerPage

  useEffect(() => {
    if (dataClassDetails) {
      setMoments(dataClassDetails?.moment)
    }
  }, [dataClassDetails])

  const handleScroll = (e: SyntheticEvent) => {
    const target = e.target as HTMLDivElement
    const isScrolled = target?.scrollTop > 0
    setHasScrolled(isScrolled)
  }

  const handleLeftClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 3)
    }
  }

  const handleRightClick = () => {
    if (moments && currentIndex < moments?.length - 3) {
      setCurrentIndex(currentIndex + 3)
    }
  }

  const handleCloseModalTime = useCallback(() => {
    setOpenModalTime(false)
  }, [])

  const location = useLocation()
  const handleSchedule = useCallback(() => {
    if (classId && trailId) {
      sessionStorage.setItem('previousPath', location.pathname)
      navigate(`/schedule/${classId}/track/${trailId}`)
    }
  }, [classId, trailId, location.pathname, navigate])

  useEffect(() => {
    return () => {
      removeClassDetails()
      removeClassInfos()
    }
  }, [trailId, classId, removeClassDetails, removeClassInfos])

  const handleOpenModalBNCC = useCallback(() => {
    setOpenModalBNCC(true)
  }, [])

  const handleCloseModalBNCC = useCallback(() => {
    setOpenModalBNCC(false)
  }, [])

  const handleOpenModalSchedule = useCallback(() => {
    handleOpenModalSchedule()
  }, [])

  useEffect(() => {
    if (moments) {
      if (checkMyLessons === true) {
        const newMoments = moments.filter((moment) => moment?.professor?.idUserProfessor === user?.id_user)
        setMoments(newMoments)
      } else {
        setMoments(dataClassDetails?.moment)
      }
    }
  }, [checkMyLessons, dataClassDetails, moments, user?.id_user])

  const handleActiveCard = (index: number) => {
    setTrailIndex(index)
  }

  function handleAllLessons() {
    setCurrentIndex(0)
  }

  return (
    <S.ContainerScroll onScroll={handleScroll}>
      <AppBarComponent />
      <ContainerComponent>
        <TrailsHeaderComponent
          isDisabledClasses={dataClassDetails?.class[0]?.inStatus}
          path={`/trail/${trailId}/project/${classId}`}
          txTitle={dataClassDetails?.txTitle}
          txClassShift={dataClassDetails?.class[0]?.classShift?.txClassShift}
          txGrade={dataClassDetails?.class[0]?.schoolGrade?.grade?.txGrade as string}
          coStage={dataClassDetails?.coStage}
          txSchoolGradeName={dataClassDetails?.class[0]?.txName as string}
          classGrade={dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.txGrade}
          component={dataClassDetails?.component}
          professor={dataClassDetails?.professor}
          moment={dataClassDetails?.moment?.sort(
            (a: any, b: any) => Number(new Date(a.dtSchedule)) - Number(new Date(b.dtSchedule)),
          )}
          loading={isLoadingTrailsDetails}
          IdLesson={Number(trailId)}
          stage={dataClassDetails?.stage}
          actionOpenModalBNCC={handleOpenModalBNCC}
          actionOpenModalSchedule={handleOpenModalSchedule}
          actionOpenModalTime={handleSchedule}
          hasScrolled={hasScrolled}
        />

        <DividerComponent />

        <S.ContainerLesson hasScrolled={hasScrolled}>
          <CardDescription description={dataClassDetails?.txDescription} />

          <StageSteps
            data={dataClassDetails}
            onChangeTrailIndex={trailIndex}
            handleAllLessons={handleAllLessons}
            handlePrevClick={handleLeftClick}
            handleNextClick={handleRightClick}
            isPrevDisabled={isPrevDisabled}
            isNextDisabled={isNextDisabled}
            adjustedCurrentIndex={adjustedCurrentIndex}
            currentIndex={currentIndex}
            itemsPerPage={itemsPerPage}
            isTrailGenerated={true}
          />

          <S.CardSliderResumeContainer isMobile={Boolean(isMobile)}>
            {sortedLessons?.slice(adjustedCurrentIndex, adjustedCurrentIndex + itemsPerPage).map((lesson, index) => (
              <CardDetailTrail
                key={index}
                index={index}
                dataTrail={lesson}
                handleActiveCard={() => handleActiveCard(lesson?.nuOrder - 1)}
                isTrailGenerated={true}
                idClass={Number(classId)}
                variant="P2"
                moment={sortedMoments?.find((moment) => moment?.nuTrackOrder === lesson?.nuOrder)}
                itemsPerPage={itemsPerPage}
                handleSchedule={handleSchedule}
              />
            ))}
          </S.CardSliderResumeContainer>
        </S.ContainerLesson>

        <S.Container>
          <S.TimeStatusDiv>
            <S.Title>Projetos da trilha</S.Title>
          </S.TimeStatusDiv>

          {isLoadingTrailsDetails && !dataClassDetails ? (
            <SkeletonComponent variant="rounded" width={130} />
          ) : (
            dataClassDetails?.stage?.[0]?.project?.map((project, index: number) => (
              <CardProjectComponent
                key={project?.idProject}
                skill={project?.category?.txName}
                txImagePath={project?.category?.txImagePath}
                title={project?.txTitle}
                description={project?.txDescription}
                isTrailGenerated={true}
              />
            ))
          )}
        </S.Container>

        <ScheduleModalComponent
          open={openModalTime}
          actionCloseModal={handleCloseModalTime}
          idTrack={idTrack}
          coStage={dataClassDetails?.coStage}
          classId={classId}
          dataClassInfos={dataClassInfos}
          moments={moments}
          router={`/class-details/${classId}`}
          routing={true}
          type={validateType(dataClassDetails?.coStage as string, 'edit')}
          queryName={['TrailProjectF2']}
          refetch={refetchDataClass}
          title={dataClassDetails?.txTitle as string}
        />
        <ModalBNCCComponent
          open={openModalBNCC}
          txGuidanceBNCC={dataClassDetails?.txGuidanceBNCC}
          actionCloseModal={handleCloseModalBNCC}
        />
      </ContainerComponent>
    </S.ContainerScroll>
  )
}
