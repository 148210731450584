import { Alert, Box, Tooltip, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import { Theme } from '@mui/system'
import styled from 'styled-components'

interface IArrowProps {
  enabled: boolean
}

export const ContentModal = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const InfoAlert = styledMUI(Alert)(() => ({
  backgroundColor: '#E8F6FF',
  color: '#0095FF',
  '& .MuiAlert-icon': {
    color: '#0095FF',
  },
}))

export const WarnningAlert = styledMUI(Alert)(() => ({
  backgroundColor: '#FFF7DD',
  color: '#FF8A00',
  '& .MuiAlert-icon': {
    color: '#FF8A00',
  },
}))

export const SuccessAlert = styledMUI(Alert)(() => ({
  backgroundColor: '#E1FFEB',
  color: '#0CD04D',
  '& .MuiAlert-icon': {
    color: '#0CD04D',
  },
}))

interface GeneralProps {
  theme: Theme
}

export const General = styled(Box)<GeneralProps>`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 16px;
  height: 50%;
  @media (max-width: 600px) {
    padding: 0;
  }
`

export const WrapperHeader = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const FlexWrapperArrow = styled.div`
  display: flex;
  width: 105%;
  margin-top: 100px;
  margin-left: -32px;
  justify-content: space-between;
  position: absolute;
`

export const IconAlert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.warningPure};
  padding: 2px;
  width: 64px;
`

export const ChipTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border: 1px solid ${({ theme }) => theme.colors.midDark};
  border-radius: 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.midDark};
  width: 70px;
  height: 24px;
`
export const TooltipLabel = styledMUI(Tooltip)(() => ({}))

export const Arrow = styled.button<IArrowProps>`
  display: flex;
  align-items: center;
  border: solid 0px;
  justify-content: center;
  background-color: ${({ theme, enabled }) => (enabled ? theme.colors.actionPure : theme.colors.midMedium)};
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.highPure};
  border-radius: 50%;
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'normal')};
`

export const ArrowCollapse = styled.div<IArrowProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.midMedium};
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.midDark};
  border-radius: 50%;
  cursor: pointer;
`
export const PutEnd = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '16px',
}))

export const PutStart = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
}))

export const TextTitle = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '28.8px',
  color: '#0095FF',
  '@media (max-width: 600px)': {
    fontSize: '24px',
  },
}))

export const TextDescription = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#7C8189',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}))

export const ContentStudents = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  border: '1px dashed #BDC3CF',
  borderRadius: '8px',
  '@media (max-width: 600px)': {
    maxHeight: '150px',
  },
}))

export const ContentStudentsPic = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  backgroundColor: '#f9fafc',
  alignItems: 'flex-start',
  marginBottom: '16px',
  padding: '16px 16px 0px 16px',
  '@media (max-width: 600px)': {
    maxHeight: '150px',
  },
}))

export const TitleLabelStudents = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '2px',
  textAlign: 'left',
  lineHeight: '120%',
  color: '#0095FF',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}))

export const TextLabelStudents = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '120%',
  color: '#7C8189',
  marginBottom: '12px',
}))

export const StudentsGroupContainer = styledMUI(Box, {
  shouldForwardProp: (prop) => prop !== 'showGroup',
})<{ showGroup?: boolean }>(({ showGroup }) => ({
  display: 'grid',
  gridTemplateColumns: `${showGroup === true ? '72px' : '0px'} auto`,
  width: '800px',
  gap: '5px',
}))

export const GroupIdentifier = styledMUI(Box)<{ totalGroup: number }>(({ totalGroup }) => ({
  textAlign: 'left',
  width: '100%',
  color: totalGroup < 3 || totalGroup > 7 ? '#FF8A00' : '#7C8189',
  fontSize: '12px',
  lineHeight: '12px',
  fontWeight: 700,
  paddingTop: '40px',
  paddingLeft: '5px',
}))

export const ListStudents = styledMUI(Box)(() => ({
  minHeight: '88px',
  width: '100%',
  display: 'flex',
  marginBottom: '16px',
  alignItems: 'center',
  backgroundColor: '#fff',
  boxShadow: '0px 4px 8px 0px #00000014',
  borderRadius: '8px',
  padding: '2px 14px',
  transition: 'background-color 0.3s ease-in-out, border 0.3s ease-in-out',
  gap: '4px',
  '@media (max-width: 600px)': {
    gap: '0',
  },
}))

export const ContainerAbsentStudents = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  marginBottom: '16px',
  gap: '8px',
  background: '#F9FAFC',
  borderRadius: '8px',
}))

export const TitleAbsentStudents = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '19.2px',
  color: '#7C8189',
}))

export const ContentAbsentStudents = styledMUI(Box)<{ absentStudentsCount?: number }>(({ absentStudentsCount }) => ({
  justifyContent: !absentStudentsCount ? 'center' : 'left',
  alignItems: !absentStudentsCount ? 'center' : 'left',
  minHeight: '86px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: '8px',
  gap: '8px',
  border: '1px dashed #7C8189',
  borderRadius: '8px',
  flexFlow: 'row wrap',
}))

export const TextAbsentStudents = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '120%',
  color: '#7C8189',
}))

export const ContainerButtons = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const ContentButtons = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '16px',
  paddingBottom: '0px',
  '@media (max-width: 600px)': {
    marginTop: '24px',
    flexDirection: 'column-reverse',
  },
}))
