import { Fragment, useState } from 'react'

import parse from 'html-react-parser'

import { AppBarComponent, ContainerComponent } from '~/components'

import { HeaderComponent } from '~/containers/private/Fund2/ProjectBNCCRelations'

import { useLessonBNCCRelationsProjectPageContext } from './context'
import * as S from './styles'

export const LessonBNCCRelationsView = () => {
  const { projects } = useLessonBNCCRelationsProjectPageContext()
  const [activedTab, setActivedTab] = useState<number>(0)

  return (
    <>
      <AppBarComponent />
      <ContainerComponent>
        <HeaderComponent
          loading={false}
          isOffline
          projectStage={projects}
          title="Integração com o currículo"
          description="Consulte aqui a relação das Referências da BNCC para essa aula."
          activedTab={activedTab}
          actionSetActiveTab={(newActivedTab: number) => setActivedTab(newActivedTab)}
        />

        <>
          {[0, 1, 2].map(
            (tab) =>
              activedTab === tab && (
                <Fragment key={tab}>
                  <S.ContentParagraph>
                    <S.TextParagraph>{parse(String(projects?.[tab]?.projectStage?.txGuidanceBncc))}</S.TextParagraph>
                  </S.ContentParagraph>
                </Fragment>
              ),
          )}
        </>
      </ContainerComponent>
    </>
  )
}
