import { Box } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

export const ContentParagraph = styledMUI(Box)(() => ({
  margin: '200px 24px 24px',
  backgroundColor: '#F9FAFC',
  borderRadius: '8px',
  padding: '16px',
  marginTop: '270px',
  '@media (max-width: 600px)': {
    marginTop: '330px',
  },
}))

export const TextParagraph = styledMUI(Box)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#000',
}))
