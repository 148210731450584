import { Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled, { css } from 'styled-components'

export const Container = styled.div<{ variant: 'one' | 'two' | 'three' }>`
  width: 100%;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows['shadow-level-3']};
    opacity: 0.9;
  }

  ${({ variant }) =>
    variant === 'one' &&
    css`
      background-color: ${({ theme }) => theme.colors.primaryPure};
      max-height: 56px;
    `}

  ${({ variant }) =>
    variant === 'two' &&
    css`
      background-color: ${({ theme }) => theme.colors.quaternaryPure};
    `}

  ${({ variant }) =>
    variant === 'three' &&
    css`
      background-color: ${({ theme }) => theme.colors.secondaryPure};
      max-height: 56px;
    `}
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ContentActivity = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`

export const ContentLabels = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`

export const TextStepLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.highPure};
`

export const TextStepDescription = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.highPure};
`

export const ContentActivities = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0 0 0;
  cursor: default;
`

export const CardActivity = styled.div`
  flex-direction: row;
  height: 100%;
  width: 100%;
  display: block;
  align-items: center;
  padding: 8px;
  gap: 4px;
  background-color: ${({ theme }) => theme.colors.quaternaryLight};
  border-radius: 8px;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows['shadow-level-1']};
  }
`

export const TextStepDescriptionActivity = styledMUI(Typography)<{ color: string }>(({ variant }) => ({
  fontSize: '14px',
  border: 'none',
  color: variant,
  lineHeight: '120%',
  '& span:first-child': {
    fontWeight: 400,
  },
  '& span': {
    fontWeight: 700,
    maxWidth: '90%',
  },
}))

export const textLesson = styled.span``

export const Arrow = styled.div`
  flex-direction: column;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.quaternaryPure};
  transition: all 0.5s;

  &:hover {
    color: ${({ theme }) => theme.colors.quaternaryDark};
  }

  @media (max-width: 768px) {
    margin-top: -14%;
    margin-left: 55%;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.quaternaryPure};
  transition: all 0.5s;

  &:hover {
    color: ${({ theme }) => theme.colors.quaternaryDark};
  }
`

export const ContentArrow = styled.div<{ variant: 'one' | 'two' | 'three' }>`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  ${({ variant }) =>
    variant === 'one' &&
    css`
      background-color: ${({ theme }) => theme.colors.primaryMedium};
    `}

  ${({ variant }) =>
    variant === 'two' &&
    css`
      background-color: ${({ theme }) => theme.colors.quaternaryMedium};
    `}

  ${({ variant }) =>
    variant === 'three' &&
    css`
      background-color: ${({ theme }) => theme.colors.secondaryMedium};
    `}
`

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
`

export const WrapperEvidence = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 10px;
  gap: 4px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
export const Label = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.2px;
  color: #000;
`
export const TextChallenge = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: #000;
`
