import { IconComponent, SkeletonComponent } from '~/components'

import { truncateText } from '~/validations/truncateText'

import { ITrailDetailsProjectProps } from './CardProject.interfaces'
import * as S from './CardProject.styles'

export const CardProjectComponent = ({
  loading,
  icon,
  skill,
  title,
  description,
  txImagePath,
  isTrailGenerated,
}: ITrailDetailsProjectProps) => {
  return (
    <S.Content>
      <S.Card isTrailGenerated={isTrailGenerated}>
        {loading ? (
          <SkeletonComponent width={300} />
        ) : (
          <S.CardInfo>
            {icon}
            {txImagePath && <IconComponent color="#0095FF" fontWeight="normal" code={txImagePath} size="xsmall" />}

            <S.Title>
              {skill} <b>{truncateText(title || '', 63)}</b>
            </S.Title>
          </S.CardInfo>
        )}

        <S.TextParagraph>
          {loading ? (
            <SkeletonComponent width={500} />
          ) : (
            <span>
              <strong> Desafio do projeto: </strong>
              {description}
            </span>
          )}
        </S.TextParagraph>
      </S.Card>
    </S.Content>
  )
}
