import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import { useGetStudentsGroupProject } from '~/services/Students'

import { AvatarGroupComponent, DropdownSkillComponent, IconComponent } from '~/components'

import { EvidenceVariable, ProjectMomentStage, ProjectStage } from '~/pages/private/Fund2/Project/interfaces'

import { colorsStepsActived } from '~/validations'

import * as S from './VariableEvidences.styles'

interface IVariableEvidenceProps {
  isOpen: boolean
  onToggle: () => void
  projectStage: ProjectStage
  evidenceVariable: EvidenceVariable
  idProjectMomentStage: number
  index: number
}

interface IVariableEvidenceComponentProps {
  projectMomentStage: ProjectMomentStage[]
}

export const VariableEvidence = ({
  evidenceVariable,
  projectStage,
  isOpen,
  onToggle,
  idProjectMomentStage,
  index,
}: IVariableEvidenceProps) => {
  const { projectId } = useParams()

  const { data: dataStudentsPayload } = useGetStudentsGroupProject(Number(projectId))

  const filteredStudentsGroup = dataStudentsPayload?.group?.filter(
    (item: any) => item.idProjectMomentStage === idProjectMomentStage,
  )

  const color =
    index === 0
      ? colorsStepsActived('firstStep')
      : index === 1
      ? colorsStepsActived('secondStep')
      : colorsStepsActived('thirdStep')

  return (
    <S.ContainerEvidence>
      <Box>
        <S.TitleContainer>
          <IconComponent
            color={color}
            fontWeight="normal"
            code={projectStage?.project?.projectCategory?.[0]?.category?.txImagePath}
            size="xsmall"
          />
          <S.TitleEvidence color={color}>
            <span>{projectStage?.project?.projectCategory?.[0]?.category?.txName} - </span>{' '}
            {projectStage?.project?.txTitle}
          </S.TitleEvidence>
        </S.TitleContainer>
        <DropdownSkillComponent
          isOpen={isOpen}
          onToggle={onToggle}
          txSkillName={evidenceVariable?.ability?.txName}
          txSkillDescription={evidenceVariable?.txName}
          iconSkill={evidenceVariable?.ability?.txImagePath}
          iconCompetency={evidenceVariable?.ability?.competence?.txImagePath}
          txBncc=""
          txCompetency={evidenceVariable?.ability?.competence?.txName}
        />
      </Box>

      <S.WrapperAvatars>
        <AvatarGroupComponent images={filteredStudentsGroup} size="small" max={4} />
      </S.WrapperAvatars>
    </S.ContainerEvidence>
  )
}

export const VariableEvidencesComponent = ({ projectMomentStage }: IVariableEvidenceComponentProps) => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null)

  const handleDropdownToggle = (index: number) => {
    setOpenDropdownIndex(index === openDropdownIndex ? null : index)
  }

  return (
    <S.Container>
      <S.Title>Habilidades Variáveis</S.Title>
      {projectMomentStage?.map((item, index: number) => (
        <VariableEvidence
          key={item.idProjectMomentStage}
          index={index}
          idProjectMomentStage={item.idProjectMomentStage}
          evidenceVariable={item.projectStage.evidenceVariable}
          isOpen={index === openDropdownIndex}
          onToggle={() => handleDropdownToggle(index)}
          projectStage={item.projectStage}
        />
      ))}
    </S.Container>
  )
}
