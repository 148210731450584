import { Box, Card, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const Container = styledMUI(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'inherit',
  boxShadow: 'none',
}))

export const Content = styledMUI(Box)<{ color: string }>(({ color }) => ({
  width: '100%',
  height: '100%',
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: color,
  '@media (max-width: 600px)': {
    position: 'relative',
  },
}))

export const HeaderCardProject = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '@media (max-width: 600px)': {
    display: 'grid',
  },
}))

export const TextLabel = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#000',
}))

export const WrapperText = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'top',
  gap: '6px',
  '@media (max-width: 600px)': {
    display: 'grid',
  },
}))

export const WrapperAvatars = styledMUI(Box)(() => ({
  '@media (max-width: 600px)': {
    justifySelf: 'start',
    margin: '10px 0',
  },
}))

export const ProjectTitle = styledMUI(Typography)<{ color: string }>(({ variant }) => ({
  fontSize: '14px',
  border: 'none',
  color: variant,
  lineHeight: '120%',
  '& span:first-child': {
    fontWeight: 400,
  },
  '& span': {
    fontWeight: 700,
    maxWidth: '90%',
  },
}))

export const textProject = styled.span``

export const ContentIcon = styledMUI(Box)(() => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: '#E2E5EC',
  cursor: 'pointer',
  '@media (max-width: 600px)': {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
}))

export const Wrapper = styledMUI(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  marginTop: '20px',
  flexFlow: 'row wrap',
}))

export const WrapperEvidence = styledMUI(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  marginTop: '20px',
  '@media (max-width: 600px)': {
    display: 'grid',
  },
}))

export const Description = styledMUI(Typography)(() => ({
  color: '#000',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '150%',
  marginTop: '20px',
}))
