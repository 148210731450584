import { createContext, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useAuth } from '~/_context/Auth'

import { useProjectServiceAssessment, useProjectServiceAssessmentBatch } from '~/services/Assessment'
import { useGetProjectInformation } from '~/services/Project'
import { useGetStudentsGroupProject } from '~/services/Students'

import { IProjectPageContextProps, IProjectPageProviderProps } from './interfaces'

const ProjectPageContext = createContext<IProjectPageContextProps>({
  isLoading: false,
  dataProject: undefined,
  mutateAssessment: () => Object,
  dataStudentsPayload: {},
  isLoadingManageStudents: false,
  isSameUser: false,
  isClassInStatus: true,
})

const ProjectPageProvider = ({ children }: IProjectPageProviderProps) => {
  const { projectId } = useParams()
  const { user } = useAuth()

  const { data: dataProject, isLoading } = useGetProjectInformation(Number(projectId))

  const idUserProfessor = dataProject?.idUserProfessor
  const isClassInStatus = dataProject?.class?.inStatus

  const isSameUser = user?.id_user === idUserProfessor

  const {
    data: dataAssessment,
    mutate: mutateAssessment,
    isLoading: isLoadingManageStudents,
  } = useProjectServiceAssessment()

  const { mutate: mutateAssessmentBatch, isLoading: isLoadingAssessmentBatch } = useProjectServiceAssessmentBatch()

  const { data: dataStudentsPayload, refetch: refetchStudentsGroup } = useGetStudentsGroupProject(Number(projectId))

  useEffect(() => {
    if (!isLoadingManageStudents && dataAssessment) {
      refetchStudentsGroup().then()
    }
  }, [dataAssessment, isLoadingManageStudents, refetchStudentsGroup])

  const projectPageProviderValues = useMemo(() => {
    return {
      isLoading,
      isSameUser,
      dataProject,
      mutateAssessment,
      dataStudentsPayload,
      isLoadingManageStudents,
      mutateAssessmentBatch,
      isLoadingAssessmentBatch,
      isClassInStatus,
    }
  }, [
    isSameUser,
    isLoading,
    dataProject,
    mutateAssessment,
    dataStudentsPayload,
    isLoadingManageStudents,
    mutateAssessmentBatch,
    isLoadingAssessmentBatch,
    isClassInStatus,
  ])

  return <ProjectPageContext.Provider value={projectPageProviderValues}>{children}</ProjectPageContext.Provider>
}

const useProjectPageContext = () => useContext(ProjectPageContext)

export { ProjectPageProvider, useProjectPageContext }
