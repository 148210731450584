import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { UilBookReader, UilNotes, UilStar, UilPresentationPlay } from '@iconscout/react-unicons'
import { useTheme } from 'styled-components'

import { useOfflineMode } from '~/_context/Offline'

import { useServiceGetLesson } from '~/services/Lesson'

import { ActiveMomentCardComponent, BackNavigationComponent } from '~/components'

import { INavigationStateProps, IQuickNavigationComponentProps } from './QuickNavigation.interfaces'
import * as S from './QuickNavigation.styles'

export const QuickNavigationComponent = ({
  label,
  stepActived,
  idLessonActivity,
  statusBlock,
  isOffline = false,
  status,
}: IQuickNavigationComponentProps) => {
  const { idLessonMoment, idLesson, projectId, idClass } = useParams()

  const theme = useTheme()

  const { getOfflineInformation } = useOfflineMode()

  const lessonAcitvity = getOfflineInformation?.[0]?.lessonSteps?.find(
    (item) => item.idLessonMoment === Number(idLessonMoment) && item.nuOrder === 2,
  )?.lessonActivity

  const addOffline = (to: string) => {
    if (isOffline) {
      return `/offline${to}`
    }
    return to
  }

  const { data } = useServiceGetLesson(Number(idLessonMoment), { enabled: !isOffline && idLessonMoment !== undefined })

  const [navigationState, setNavigationState] = useState<INavigationStateProps>({
    home: false,
    firstStep: false,
    secondStep: false,
    secondStepA: false,
    secondStepB: false,
    secondStepC: false,
    secondStepD: false,
    thirdStep: false,
    documentations: false,
    assessment: false,
    materials: false,
    BNCCRelations: false,
  })

  const handleNavigation = useCallback(
    (routeActive: string) => {
      const newNavigationState: any = new Object({})

      for (const key of Object.keys(navigationState)) {
        if (key === routeActive) {
          newNavigationState[routeActive as keyof INavigationStateProps] = true
        } else {
          newNavigationState[key as keyof INavigationStateProps] = false
        }
      }

      setNavigationState(newNavigationState)
    },
    [navigationState],
  )

  const handleStepActived = (index: number) => {
    switch (index) {
      case 0:
        return 'secondStepA'
      case 1:
        return 'secondStepB'
      case 2:
        return 'secondStepC'
      case 3:
        return 'secondStepD'
      default:
        return 'secondStep'
    }
  }

  useEffect(() => {
    if (stepActived) {
      handleNavigation(stepActived)
    } else if (idLessonActivity && !isOffline) {
      const index = data?.lesson.lessonStep[1].lessonActivity.findIndex(
        (item) => item.idLessonActivity === idLessonActivity,
      )
      if (index === 0) {
        handleNavigation('secondStepA')
      } else if (index) {
        handleNavigation(handleStepActived(index))
      }
    }
    // eslint-disable-next-line
  }, [idLessonActivity, stepActived])

  return (
    <S.Container>
      <BackNavigationComponent
        label={' '}
        tooltipText={'Voltar para a aula'}
        path={addOffline(
          projectId
            ? `${isOffline ? '/' : `/class/${idClass}/`}project/${projectId}`
            : `${isOffline ? '/' : `/class/${idClass}/`}lesson/${idLessonMoment}`,
        )}
        icon={<UilPresentationPlay size={16} color="#0095ff" />}
      />

      <S.Wrapper>
        <S.ButtonNavigation
          variant="firstStep"
          active={navigationState.firstStep ? 1 : 0}
          to={addOffline(
            projectId
              ? `${isOffline ? '/' : `/class/${idClass}/`}project-firstStep/${projectId}`
              : `${isOffline ? '/' : `/class/${idClass}/`}first-step/${idLessonMoment}/lesson/${idLesson}`,
          )}
          onClick={() => handleNavigation('firstStep')}
        >
          1
        </S.ButtonNavigation>

        {projectId ? (
          <S.ButtonNavigation
            variant="secondStep"
            active={navigationState.secondStep ? 1 : 0}
            to={addOffline(`${isOffline ? '/' : `/class/${idClass}/`}project-secondStep/${projectId}`)}
            onClick={() => handleNavigation('secondStep')}
          >
            2
          </S.ButtonNavigation>
        ) : (
          <ActiveMomentCardComponent
            isOffline={isOffline}
            idClass={idClass}
            idLessonMoment={idLessonMoment}
            idLesson={idLesson}
            lessonActivity={isOffline ? lessonAcitvity : data?.lesson?.lessonStep?.[1]?.lessonActivity}
            navigationState={navigationState}
            handleNavigation={handleNavigation}
            status={status}
          />
        )}

        <S.ButtonNavigation
          variant="thirdStep"
          active={navigationState.thirdStep ? 1 : 0}
          to={addOffline(
            projectId
              ? `${isOffline ? '/' : `/class/${idClass}/`}project-thirdStep/${projectId}`
              : `${isOffline ? '/' : `/class/${idClass}/`}third-step/${idLessonMoment}/lesson/${idLesson}`,
          )}
          onClick={() => handleNavigation('thirdStep')}
        >
          3
        </S.ButtonNavigation>

        {!isOffline && status !== 'AGEN' ? (
          <>
            {statusBlock ? (
              <S.ButtonNavigation
                active={navigationState.documentations ? 1 : 0}
                to={
                  projectId
                    ? `${isOffline ? '/' : `/class/${idClass}/`}project-documentations/${projectId}`
                    : `${isOffline ? '/' : `/class/${idClass}/`}documentations/${idLessonMoment}/lesson/${idLesson}`
                }
                onClick={() => handleNavigation('documentations')}
              >
                <UilNotes
                  size={20}
                  color={
                    navigationState.documentations
                      ? theme.colors.highPure
                      : !statusBlock
                      ? theme.colors.lowLight
                      : theme.colors.actionPure
                  }
                />
              </S.ButtonNavigation>
            ) : (
              <S.ButtonAssessment active={!statusBlock}>
                <UilNotes size={20} color={theme.colors.lowLight} />
              </S.ButtonAssessment>
            )}

            {statusBlock ? (
              <S.ButtonNavigation
                active={navigationState.assessment ? 1 : 0}
                to={
                  projectId
                    ? `${isOffline ? '/' : `/class/${idClass}/`}project-assessment/${projectId}`
                    : `${isOffline ? '/' : `/class/${idClass}/`}lesson-assessment/${idLessonMoment}/lesson/${idLesson}`
                }
                onClick={() => handleNavigation('assessment')}
              >
                <UilStar
                  size={20}
                  color={navigationState.assessment ? theme.colors.highPure : theme.colors.actionPure}
                />
              </S.ButtonNavigation>
            ) : (
              <S.ButtonAssessment>
                <UilStar size={20} color={theme.colors.lowLight} />
              </S.ButtonAssessment>
            )}
          </>
        ) : (
          <>
            <S.ButtonAssessment active={!statusBlock}>
              <UilNotes size={20} color={theme.colors.lowLight} />
            </S.ButtonAssessment>
            <S.ButtonAssessment>
              <UilStar size={20} color={theme.colors.lowLight} />
            </S.ButtonAssessment>
          </>
        )}

        <S.ButtonNavigation
          active={navigationState.BNCCRelations}
          to={addOffline(
            projectId
              ? `${isOffline ? '/' : `/class/${idClass}/`}project-bncc-relations/${projectId}`
              : `${isOffline ? '/' : `/class/${idClass}/`}lesson-bncc-relations/${idLessonMoment}/lesson/${idLesson}`,
          )}
          onClick={() => handleNavigation('BNCCRelations')}
        >
          <UilBookReader
            size={20}
            color={navigationState.BNCCRelations ? theme.colors.highPure : theme.colors.actionPure}
          />
        </S.ButtonNavigation>
      </S.Wrapper>
    </S.Container>
  )
}
