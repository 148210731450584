import { useState } from 'react'

import { DropdownSkillComponent } from '~/components'

import * as S from './FixedEvidences.styles'

interface IFixedEvidenceProps {
  isOpen: boolean
  onToggle: () => void
  fixedEvidence: {
    txAbility: string
    txEvidence: string
    txIconSkill: string
    txIconCompetency: string
    txCompetency: string
  }
}

interface IFixedEvidenceComponentProps {
  fixedEvidence: {
    txAbility: string
    txEvidence: string
    txIconSkill: string
    txIconCompetency: string
    txCompetency: string
  }
}

const FixedEvidence = ({ fixedEvidence, isOpen, onToggle }: IFixedEvidenceProps) => {
  return (
    <S.ContainerEvidence>
      <DropdownSkillComponent
        isOpen={isOpen}
        onToggle={onToggle}
        txSkillName={fixedEvidence.txAbility}
        iconCompetency={fixedEvidence.txIconCompetency}
        iconSkill={fixedEvidence.txIconSkill}
        txSkillDescription={fixedEvidence.txEvidence}
        txBncc=""
        txCompetency={fixedEvidence.txCompetency}
      />
    </S.ContainerEvidence>
  )
}

export const FixedEvidencesComponent = ({ fixedEvidence }: IFixedEvidenceComponentProps) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)

  const handleDropdownToggle = () => {
    setOpenDropdown(!openDropdown)
  }

  return (
    <S.Container>
      <S.Title>Habilidades Fixa</S.Title>
      <FixedEvidence isOpen={openDropdown} onToggle={() => handleDropdownToggle()} fixedEvidence={fixedEvidence} />
    </S.Container>
  )
}
