import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { CircularProgress } from '@mui/material'

import { useGetDocumentation } from '~/services/Documentation'
import { Documentation } from '~/services/Documentation/types'

import {
  AccordionOrientationsComponent,
  AppBarComponent,
  AssessmentComponent,
  ContainerComponent,
  ModalDocumentationComponent,
  QuickNavigationComponent,
  SkeletonComponent,
  ValidateDataSessionComponent,
} from '~/components'
import { AlertAssessment } from '~/components/Forms/Assessment/Assessment.styles'

import { HeaderComponent } from '~/containers/private/Fund2/ProjectStage'

import { IDocumentProps } from '~/pages/private/Documentations/interfaces'

import { CardDocumentationComponent } from './../../../../components/Cards/CardDocumentation/CardDocumentation'
import { useProjectPageContext } from './context'
import * as S from './styles'

export const FourthStageProjectView = () => {
  const { projectId, idClass } = useParams()
  const {
    isLoading,
    dataProject,
    mutateAssessment,
    dataStudentsPayload,
    isLoadingManageStudents,
    mutateAssessmentBatch,
    isLoadingAssessmentBatch,
    isSameUser,
    isClassInStatus,
  } = useProjectPageContext()

  const [activedTab, setActivedTab] = useState<number>(0)
  const { data: dataProjectDocumentation } = useGetDocumentation(Number(idClass), Number(projectId))
  const dataStudentsGroup = dataStudentsPayload?.group
  const fixedPayload = dataProject?.projectMomentStage[3]?.projectStage?.evidenceFixed
  const variablePayload = dataProject?.projectMomentStage[3]?.projectStage?.evidenceVariable

  const documentations = dataProjectDocumentation
  const step = dataProject?.projectMomentStage[3]

  const filteredStudentsGroup = dataStudentsGroup?.filter(
    (item: any) => item.idProjectMomentStage === step?.idProjectMomentStage,
  )

  const documentationsFiltered = documentations?.filter(
    (documentation: Documentation) =>
      documentation?.projectStage?.idProjectStage === step?.projectStage?.idProjectStage,
  )

  const hasDocumentation = documentationsFiltered && documentationsFiltered?.length > 0

  const handleAssessment = (id: string, coAssessment: string, idEvidence: number) => {
    mutateAssessment({
      body: { idMomentGroup: id, coAssessment, idEvidence },
      projectId: String(projectId),
      idClass: idClass,
    })
  }

  const handleAssessmentBatch = (body) => {
    body.projectId = String(projectId)
    body.idClass = String(idClass)
    mutateAssessmentBatch(body)
  }

  const status = dataProject?.momentStatus?.coMomentStatus

  const isFinished = status === 'FINA'

  return (
    <>
      {isLoadingManageStudents ||
        (isLoadingAssessmentBatch && (
          <AlertAssessment
            iconMapping={{
              info: <CircularProgress color="inherit" size={16} />,
            }}
            severity={'info'}
          >
            Estamos processando a sua avaliação.
          </AlertAssessment>
        ))}
      <AppBarComponent />
      <ContainerComponent>
        <S.NavContainer>
          <QuickNavigationComponent
            status={dataProject?.momentStatus?.coMomentStatus}
            label="Voltar"
            stepActived="fourthStep"
            statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(status)}
          />
        </S.NavContainer>

        {isLoading ? (
          <S.HeaderLoading>
            <SkeletonComponent height={100} width={'50%'} variant="rounded" />
            <SkeletonComponent height={50} width={'50%'} variant="rounded" />
            <SkeletonComponent height={50} width={'60%'} variant="rounded" />
            <SkeletonComponent height={50} width={'80%'} variant="rounded" />
            <SkeletonComponent height={100} width={'95%'} variant="rounded" />
          </S.HeaderLoading>
        ) : (
          <HeaderComponent
            type="fourthStep"
            icon={step?.projectStage?.project?.projectCategory[0]?.category?.txImagePath}
            txCategory={step?.projectStage?.project?.projectCategory[0]?.category?.txName}
            txTitleProject={step?.projectStage?.project?.txTitle}
            students={filteredStudentsGroup}
            activedTab={activedTab}
            actionSetActiveTab={(newActivedTab: number) => setActivedTab(newActivedTab)}
            components={step?.projectStage?.project?.projectComponent}
            evidenceFixed={step?.projectStage?.evidenceFixed}
            evidenceVariable={step?.projectStage?.evidenceVariable}
            txChallengeProject={step?.projectStage?.project?.txDescription}
            txChallengeStage={step?.projectStage?.txDescription}
            studentGuidePath={step?.projectStage?.mediaInformation?.txAbsoluteUrl}
            coMomentStatus={dataProject?.momentStatus?.coMomentStatus}
            status={['AVPE', 'FINA', 'INIC'].includes(dataProject?.momentStatus?.coMomentStatus)}
          />
        )}

        <S.Content>
          {isLoading ? (
            <S.ContentLoading>
              <SkeletonComponent height={50} width={'100%'} variant="rounded" />
              <SkeletonComponent height={50} width={'100%'} variant="rounded" />
              <SkeletonComponent height={50} width={'100%'} variant="rounded" />
            </S.ContentLoading>
          ) : (
            <>
              {activedTab === 0 && (
                <S.ContentOrientations>
                  {step?.projectStage?.projectStageOrientation?.map((orientation, index: number) => (
                    <AccordionOrientationsComponent
                      key={index}
                      type="fourthStep"
                      title={orientation?.txTitle}
                      txOrientationCode={orientation?.txOrientationCode}
                      supportReference={orientation?.projectStageSupportReference}
                    />
                  ))}
                </S.ContentOrientations>
              )}
            </>
          )}

          <>
            {activedTab === 1 && (
              <>
                {hasDocumentation ? (
                  <S.ContentDocumentation component="ul">
                    {documentationsFiltered?.map((documentation: IDocumentProps) => {
                      return (
                        <CardDocumentationComponent
                          projectData={dataProject}
                          key={documentation.idProjectDocumentation}
                          isLoading={false}
                          disabled={!isSameUser}
                          status={status}
                          projectDocumentation={documentation}
                          isProject={true}
                          isClassInStatus={isClassInStatus}
                        />
                      )
                    })}
                  </S.ContentDocumentation>
                ) : (
                  <ValidateDataSessionComponent
                    screen="documentation"
                    height={50}
                    title="Nenhuma documentação cadastrada."
                    description="Até o momento, nenhuma documentação foi cadastrada."
                  />
                )}
              </>
            )}
          </>

          {activedTab == 2 && (
            <>
              <AssessmentComponent
                disabled={isFinished || !isSameUser}
                handleDrop={(id, coAssessment) => handleAssessment(id, coAssessment, variablePayload?.idEvidence)}
                handleDropBatch={handleAssessmentBatch}
                txEvidenceName={variablePayload?.ability?.txName}
                txEvidenceDescription={variablePayload?.txName}
                indexEvidence={1}
                lengthEvidence={2}
                txCompentencyName={variablePayload?.ability?.competence?.txName}
                txIconCompetency={variablePayload?.ability?.competence?.txImagePath}
                txIconSkill={variablePayload?.ability?.txImagePath}
                students={dataStudentsPayload}
                idEvidence={variablePayload?.idEvidence}
                skillType={'VAR'}
                type={'project'}
                idActivity={step.idProjectMomentStage}
              />
              <AssessmentComponent
                disabled={isFinished || !isSameUser}
                handleDrop={(id, coAssessment) => handleAssessment(id, coAssessment, fixedPayload?.idEvidence)}
                handleDropBatch={handleAssessmentBatch}
                txEvidenceName={fixedPayload?.ability?.txName}
                txEvidenceDescription={fixedPayload?.txName}
                indexEvidence={2}
                lengthEvidence={2}
                txCompentencyName={fixedPayload?.ability?.competence?.txName}
                txIconCompetency={fixedPayload?.ability?.competence?.txImagePath}
                txIconSkill={fixedPayload?.ability?.txImagePath}
                students={dataStudentsPayload}
                idEvidence={fixedPayload?.idEvidence}
                skillType={'FIX'}
                type={'project'}
                idActivity={step.idProjectMomentStage}
              />
            </>
          )}
        </S.Content>
        <>
          {['AVPE', 'INIC', 'AUPE', 'AUPL'].includes(status) && isSameUser && (
            <ModalDocumentationComponent
              idProject={Number(dataProject?.idProjectMoment)}
              variant="floater"
              isClassInStatus={isClassInStatus}
            />
          )}
        </>
      </ContainerComponent>
    </>
  )
}
