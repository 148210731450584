import { Fragment, useState } from 'react'

import parse from 'html-react-parser'

import { AppBarComponent, ContainerComponent, ModalDocumentationComponent, SkeletonComponent } from '~/components'

import { HeaderComponent } from '~/containers/private/Fund2/ProjectBNCCRelations'

import { useProjectBNCCRelationsPageContext } from './context'
import * as S from './styles'

export const ProjectBNCCRelationsView = () => {
  const { isLoading, data } = useProjectBNCCRelationsPageContext()
  const [activedTab, setActivedTab] = useState<number>(0)

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <HeaderComponent
          projectStage={data?.projectMomentStage}
          title="Integração com o currículo"
          loading={isLoading}
          description="Consulte aqui a relação das Referências da BNCC para essa aula."
          activedTab={activedTab}
          actionSetActiveTab={(newActivedTab: number) => setActivedTab(newActivedTab)}
          statusBlock={['AVPE', 'FINA', 'INIC', 'AUPE', 'AUPL'].includes(data?.momentStatus?.coMomentStatus as string)}
          status={data?.momentStatus?.coMomentStatus}
        />

        <>
          {[0, 1, 2].map(
            (tab) =>
              activedTab === tab && (
                <Fragment key={tab}>
                  {isLoading ? (
                    <SkeletonComponent width={300} />
                  ) : (
                    <S.ContentParagraph>
                      <S.TextParagraph>
                        {parse(String(data?.projectMomentStage[tab]?.projectStage?.txGuidanceBncc))}
                      </S.TextParagraph>
                    </S.ContentParagraph>
                  )}
                </Fragment>
              ),
          )}
        </>
        <>
          {['AVPE', 'INIC', 'AUPE', 'AUPL'].includes(data?.momentStatus?.coMomentStatus as string) && (
            <ModalDocumentationComponent idProject={Number(data?.idProjectMoment)} variant="floater" />
          )}
        </>
      </ContainerComponent>
    </>
  )
}
