import React, { useEffect, useRef, useState } from 'react'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AppBarComponent, ContainerComponent } from '~/components'

import {
  FixedEvidencesComponent,
  HeaderPlanning,
  ProjectGeneralOrientation,
  ProjectStepAccordion,
  SkeletonLoading,
  VariableEvidencesComponent,
} from '~/containers/private/Fund2/Planning'

import { useProjectPlanningPageContext } from '~/pages/private/Fund2/Planning/context'

import * as S from './styles'

export const ProjectPlanningView = () => {
  const { projectMomentStage, isLoading, titleNuOrder, fixedEvidence, txTitleLesson, txOrientation } =
    useProjectPlanningPageContext()

  const { width } = useWindowDimensions()
  const isMobile = width && width < 768

  const [expadedFirstStep, setExpadedFirstStep] = useState(true)
  const [expadedSecondStep, setExpadedSecondStep] = useState(true)
  const [expadedThirdStep, setExpadedThirdStep] = useState(true)
  const [expadedFourStep, setExpadedFourStep] = useState(true)

  const overallStepRef = useRef<HTMLDivElement>(null)
  const firstStepRef = useRef<HTMLDivElement>(null)
  const secondStepRef = useRef<HTMLDivElement>(null)
  const thirdStepRef = useRef<HTMLDivElement>(null)
  const fourStepRef = useRef<HTMLDivElement>(null)

  const [activedTab, setActivedTab] = useState(0)

  const scrollToNextStep = (ref: React.RefObject<HTMLDivElement>) => {
    const headerHeight = document.getElementById('header')?.clientHeight ?? 0
    const position = isMobile ? headerHeight - 20 : headerHeight + 50
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - position,
      })
    }
  }

  const handleSetActiveTab = (newActivedTab: number) => {
    switch (newActivedTab) {
      case 0:
        scrollToNextStep(overallStepRef)
        break
      case 1:
        setExpadedFirstStep(true)
        setTimeout(
          () => {
            scrollToNextStep(firstStepRef)
          },
          expadedFirstStep ? 0 : 200,
        )
        break
      case 2:
        setExpadedSecondStep(true)
        setTimeout(
          () => {
            scrollToNextStep(secondStepRef)
          },
          expadedSecondStep ? 0 : 200,
        )
        break
      case 3:
        setExpadedThirdStep(true)
        setTimeout(
          () => {
            scrollToNextStep(thirdStepRef)
          },
          expadedThirdStep ? 0 : 200,
        )
        break
      case 4:
        setExpadedFourStep(true)
        setTimeout(
          () => {
            scrollToNextStep(fourStepRef)
          },
          expadedFourStep ? 0 : 200,
        )
        break
      default:
        break
    }
    setActivedTab(newActivedTab)
  }

  const handleScroll = () => {
    const firstStepOffset = firstStepRef.current?.offsetTop ?? 0
    const secondStepOffset = secondStepRef.current?.offsetTop ?? 0
    const thirdStepOffset = thirdStepRef.current?.offsetTop ?? 0
    const fourStepOffset = fourStepRef.current?.offsetTop ?? 0

    const scrollPosition = window.scrollY + 280

    if (scrollPosition < firstStepOffset) {
      setActivedTab(0)
    } else if (scrollPosition >= firstStepOffset && scrollPosition < secondStepOffset) {
      setActivedTab(1)
    } else if (scrollPosition >= secondStepOffset && scrollPosition < thirdStepOffset) {
      setActivedTab(2)
    } else if (scrollPosition >= thirdStepOffset || scrollPosition < fourStepOffset) {
      setActivedTab(3)
    } else if (scrollPosition >= fourStepOffset) {
      setActivedTab(4)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        {isLoading ? (
          <SkeletonLoading />
        ) : (
          <>
            {projectMomentStage && (
              <HeaderPlanning
                txNameLesson={titleNuOrder}
                handleSetActiveTab={handleSetActiveTab}
                activedTab={activedTab}
                stages={projectMomentStage}
                txTitleLesson={txTitleLesson}
                txOrientation={txOrientation}
                firstStep={projectMomentStage?.[0]?.projectStage?.project?.txTitle}
                secondStep={projectMomentStage?.[1]?.projectStage?.project?.txTitle}
                thirdStep={projectMomentStage?.[2]?.projectStage?.project?.txTitle}
              />
            )}
            <S.Container>
              <S.FixedEvidencesContainer>
                <FixedEvidencesComponent fixedEvidence={fixedEvidence} />
              </S.FixedEvidencesContainer>
              {projectMomentStage && (
                <S.VariableEvidencesContainer>
                  <VariableEvidencesComponent projectMomentStage={projectMomentStage} />
                </S.VariableEvidencesContainer>
              )}
              {txTitleLesson && txTitleLesson.length > 0 && (
                <ProjectGeneralOrientation
                  title={txOrientation}
                  accordionRef={overallStepRef}
                  key={'GeneralOrientation'}
                />
              )}
              {projectMomentStage?.[0] && (
                <ProjectStepAccordion
                  title={`${projectMomentStage?.[0]?.projectStage?.project?.projectCategory?.[0]?.category?.txName} - ${projectMomentStage?.[0]?.projectStage?.project?.txTitle}`}
                  stage={projectMomentStage?.[0]}
                  expanded={expadedFirstStep}
                  index={0}
                  onToggle={() => setExpadedFirstStep(!expadedFirstStep)}
                  accordionRef={firstStepRef}
                  key={`${projectMomentStage?.[0]?.idProjectMomentStage}`}
                />
              )}
              {projectMomentStage?.[1] && (
                <ProjectStepAccordion
                  index={1}
                  title={`${projectMomentStage?.[1]?.projectStage?.project?.projectCategory?.[0]?.category?.txName} - ${projectMomentStage?.[1]?.projectStage?.project?.txTitle}`}
                  stage={projectMomentStage?.[1]}
                  expanded={expadedSecondStep}
                  onToggle={() => setExpadedSecondStep(!expadedSecondStep)}
                  accordionRef={secondStepRef}
                  key={`${projectMomentStage?.[1]?.idProjectMomentStage}`}
                />
              )}
              {projectMomentStage?.[2] && (
                <ProjectStepAccordion
                  index={2}
                  title={`${projectMomentStage?.[2]?.projectStage?.project?.projectCategory?.[0]?.category?.txName} - ${projectMomentStage?.[2]?.projectStage?.project?.txTitle}`}
                  stage={projectMomentStage?.[2]}
                  onToggle={() => setExpadedThirdStep(!expadedThirdStep)}
                  expanded={expadedThirdStep}
                  accordionRef={thirdStepRef}
                  key={`${projectMomentStage?.[2]?.idProjectMomentStage}`}
                />
              )}
              {projectMomentStage?.[3] && (
                <ProjectStepAccordion
                  index={3}
                  title={`${projectMomentStage?.[3]?.projectStage?.project?.projectCategory?.[0]?.category?.txName} - ${projectMomentStage?.[3]?.projectStage?.project?.txTitle}`}
                  stage={projectMomentStage?.[3]}
                  onToggle={() => setExpadedFourStep(!expadedFourStep)}
                  expanded={expadedFourStep}
                  accordionRef={fourStepRef}
                  key={`${projectMomentStage?.[3]?.idProjectMomentStage}`}
                />
              )}
            </S.Container>
          </>
        )}
      </ContainerComponent>
    </>
  )
}
