import { useNavigate, useParams } from 'react-router-dom'

import { UilArrowRight } from '@iconscout/react-unicons'

import { AvatarGroupComponent, DropdownSkillComponent, IconComponent } from '~/components'

import { backgroundColorsStepsActived, colorsStepsActived } from '~/validations/colorsStepsActived'

import { IProjectCardComponentProps } from './ProjectCard.interfaces'
import * as S from './ProjectCard.styles'

export const ProjectCardComponent = ({
  type,
  icon,
  txProjectType,
  txProjectTitle,
  txProjectDescription,
  avatas,
  variableSkill,
  isOffline,
}: IProjectCardComponentProps) => {
  const navigate = useNavigate()
  const { projectId, idClass } = useParams()

  const studentsFiltered = avatas?.filter((student: any) => student.inAttendance === true)
  const avatarList = avatas?.map((student: any) => student.studentClass.student)

  const addOffline = (to: string) => {
    if (isOffline) {
      return `/offline${to}`
    }
    return to
  }

  const routerLink = (type: string) => {
    switch (type) {
      case 'fourthStep':
        return 'firstStep'
      default:
        return type
    }
  }

  return (
    <S.Container>
      <S.Content color={backgroundColorsStepsActived(type)}>
        <S.HeaderCardProject>
          <S.WrapperText>
            {icon && <IconComponent color={colorsStepsActived(type)} fontWeight="normal" code={icon} size="xsmall" />}
            <S.ProjectTitle color={colorsStepsActived(type)}>
              <S.textProject>{txProjectType}</S.textProject> - <S.textProject>{txProjectTitle}</S.textProject>
            </S.ProjectTitle>
          </S.WrapperText>

          <S.WrapperText>
            <S.WrapperAvatars>
              <AvatarGroupComponent images={studentsFiltered} size="small" max={4} />
            </S.WrapperAvatars>

            <S.ContentIcon>
              <UilArrowRight
                size={18}
                color="#7C8189"
                onClick={() =>
                  navigate(
                    addOffline(`${isOffline ? '/' : `/class/${idClass}/`}project-${routerLink(type)}/${projectId}`),
                  )
                }
              />
            </S.ContentIcon>
          </S.WrapperText>
        </S.HeaderCardProject>
        <S.WrapperEvidence>
          <S.TextLabel variant="caption">Habilidade Variável</S.TextLabel>

          <DropdownSkillComponent
            txSkillName={variableSkill?.ability?.txName}
            txSkillDescription={variableSkill?.txName}
            iconSkill={variableSkill?.ability?.txImagePath}
            iconCompetency={variableSkill?.ability?.competence?.txImagePath}
            txBncc=""
            txCompetency={variableSkill?.ability?.competence?.txName}
          />
        </S.WrapperEvidence>

        <S.Description variant="body1">{txProjectDescription}</S.Description>
      </S.Content>
    </S.Container>
  )
}
