import { CustomDndProvider } from '~/components'

import { ProjectPageProvider } from './context'
import { ThirdStageProjectView } from './view'

export const ThirdStageProjectPage = () => {
  return (
    <ProjectPageProvider>
      <CustomDndProvider>
        <ThirdStageProjectView />
      </CustomDndProvider>
    </ProjectPageProvider>
  )
}
