import { useNavigate, useParams } from 'react-router-dom'

import { UilArrowRight } from '@iconscout/react-unicons'
import { useTheme } from 'styled-components'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AvatarGroupComponent, DropdownSkillComponent, IconComponent, SkeletonComponent } from '~/components'

import { LessonActivity } from '~/pages/private/Fund1/Lesson/interfaces'

import { ICardStepComponent, IVariantsColorsProps } from './CardStep.interfaces'
import * as S from './CardStep.styles'

export const CardStepComponent = ({
  pathNavigation,
  variant,
  stepLabel,
  stepDescription,
  activities,
  students,
  idLesson,
  isLoadingStudents,
  isOffline,
}: ICardStepComponent) => {
  const theme = useTheme()
  const { idLessonMoment, idClass } = useParams()
  const { width } = useWindowDimensions()
  const isMobile = width && width <= 768

  const navigate = useNavigate()
  const variantsColorsIcons = (variant: string) => {
    const variantsColors: IVariantsColorsProps = {
      one: theme.colors.primaryDark,
      two: theme.colors.quaternaryDark,
      three: theme.colors.secondaryDark,
    }

    return variantsColors[variant as keyof IVariantsColorsProps]
  }

  const renderActivity = (activity: LessonActivity) => {
    const evidenceData = activity?.evidence?.filter((item) => item?.coEvidenceType === 'VAR')

    return (
      <S.CardActivity key={activity.idLessonActivity}>
        <S.ContentActivity>
          <S.ContentLabels
            onClick={() =>
              navigate(
                isOffline
                  ? `/offline/second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${activity.idLessonActivity}`
                  : `/class/${idClass}/second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${activity.idLessonActivity}`,
              )
            }
          >
            <S.Row>
              <IconComponent color="card" fontWeight="normal" code={activity?.category.txImagePath} size="xsmall" />
              <S.TextStepDescriptionActivity color="card">
                <S.textLesson>{activity?.category?.txName}</S.textLesson> -{' '}
                <S.textLesson>{activity?.txTitle}</S.textLesson>
              </S.TextStepDescriptionActivity>
            </S.Row>
          </S.ContentLabels>
          <S.Wrapper>
            {isLoadingStudents ? (
              <SkeletonComponent width={300} />
            ) : (
              <AvatarGroupComponent
                inAttendance
                max={4}
                images={students?.group?.filter(
                  (i) => i.idLessonMomentActivity === activity.lessonMomentActivity[0].idLessonMomentActivity,
                )}
                size="small"
              />
            )}
            <S.Arrow
              onClick={() =>
                navigate(
                  isOffline
                    ? `/offline/second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${activity.idLessonActivity}`
                    : `/class/${idClass}/second-step/${idLessonMoment}/lesson/${idLesson}/activity-details/${activity.idLessonActivity}`,
                )
              }
            >
              <UilArrowRight size={20} />
            </S.Arrow>
          </S.Wrapper>
        </S.ContentActivity>
        <S.WrapperEvidence>
          <S.Label>Habilidade variável</S.Label>
          {evidenceData?.map((item, index) => (
            <DropdownSkillComponent
              key={index}
              txSkillName={item?.ability?.txName}
              iconSkill={item?.ability?.txImagePath}
              txSkillDescription={item?.txName}
              txCompetency={item?.ability?.competence?.txName}
              iconCompetency={item?.ability?.competence?.txImagePath}
            />
          ))}
        </S.WrapperEvidence>
        <S.WrapperEvidence>
          <S.Label>Desafio</S.Label>
          <S.TextChallenge>{activity?.txChallenge}</S.TextChallenge>
        </S.WrapperEvidence>
      </S.CardActivity>
    )
  }

  return (
    <S.Container variant={variant}>
      <S.Content onClick={() => navigate(pathNavigation)}>
        <S.ContentLabels>
          <S.TextStepLabel>{stepLabel}</S.TextStepLabel>

          <S.TextStepDescription>{stepDescription}</S.TextStepDescription>
        </S.ContentLabels>

        <S.ContentArrow variant={variant}>
          <UilArrowRight size={20} color={variantsColorsIcons(variant)} />
        </S.ContentArrow>
      </S.Content>
      <S.ContentActivities>
        {variant === 'two' && activities?.map((activity) => renderActivity(activity))}
      </S.ContentActivities>
    </S.Container>
  )
}
