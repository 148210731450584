import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

export const Content = styledMUI(Box)(() => ({
  backgroundColor: '#fff',
  padding: '16px 0 8px',
}))

export const Card = styled.div<{
  isTrailGenerated?: boolean
}>`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  gap: 16px;
  cursor: ${({ isTrailGenerated }) => (isTrailGenerated ? 'default' : 'pointer')};
  background-color: ${({ theme }) => theme.colors.actionLight};
`

export const Title = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '120%',
  color: '#0095FF',
}))

export const TextParagraph = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '120%',
}))

export const CardInfo = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  gap: '8px',
}))

export const Component = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  color: '#666',
  backgroundColor: '#FFF',
  borderRadius: '4px',
  padding: '4px 8px',
}))

export const Wrapper = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '8px',
}))
