import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { QuickNavigationComponent, StepsTabsComponent } from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({
  title,
  description,
  activedTab,
  actionSetActiveTab,
  projectStage,
  statusBlock,
  isOffline,
  status,
}: IHeaderComponentProps) => {
  const { width } = useWindowDimensions()

  const isMobile = width && width < 768

  return (
    <S.HeaderContainer component="header">
      <QuickNavigationComponent
        status={status}
        isOffline={isOffline}
        label="Voltar"
        stepActived="BNCCRelations"
        statusBlock={statusBlock}
      />

      <S.Title variant="h1">{title}</S.Title>

      <S.TextLabel variant="caption">{description}</S.TextLabel>

      <StepsTabsComponent
        type={'default'}
        steps={projectStage?.map((step: any, index: number) => {
          return {
            label: 'Projeto ' + (index + 1) + ' - ',
            labelDescription: step?.projectStage?.project?.projectCategory[0]?.category?.txName,
          }
        })}
        activedTab={activedTab}
        actionSetActiveTab={(newActivedTab: number) => actionSetActiveTab(newActivedTab)}
        mobile={isMobile}
      />
    </S.HeaderContainer>
  )
}
