import { CustomDndProvider } from '~/components'

import { ProjectPageProvider } from './context'
import { FourthStageProjectView } from './view'

export const FourthStageProjectPage = () => {
  return (
    <ProjectPageProvider>
      <CustomDndProvider>
        <FourthStageProjectView />
      </CustomDndProvider>
    </ProjectPageProvider>
  )
}
